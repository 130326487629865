import styled from 'styled-components'

export const VideoComponent = styled.video`
  display: none;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: block;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

interface VideoBackgroundProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  source: string
  type: string
}

export const VideoBackground = ({ source, type, ...props }: VideoBackgroundProps): JSX.Element => {
  return (
    <VideoComponent autoPlay muted loop {...props}>
      <source src={source} type={type} />
    </VideoComponent>
  )
}
