import styled from 'styled-components'

export const Container = styled.main`
  background: url('/assets/images/BG-2-Institucional.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 2000px;
  padding: 1rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    background-size: 140%;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    .content-container {
      display: flex;
      max-width: 1024px;
      margin: 0 auto;
      justify-content: space-between;
      gap: 4rem;
      margin-bottom: 4rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.xxl}) {
    background-size: 3000px;
  }
`

export const ContentContainer = styled.div`
  margin-top: 3rem;
  padding: 2.675rem;
  background: url('/assets/images/BG-White-transparent.png') no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align: center;
    font-weight: 700;
    margin-top: 0.675rem;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    width: 38%;
    margin-top: 0;
    margin-top: -2rem;
  }
`

export const ContentBody = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p {
      font-size: 14px;
      line-height: 1.5;
    }
    width: 50%;
  }
`

export const ContentMainImage = styled.img`
  margin-bottom: 2rem;
  width: 90%;
  filter: opacity(0.8);
`

export const ContentMainSecondaryImage = styled.img`
  border-radius: 10px;
  width: 98%;
  filter: opacity(0.9);
`

export const ContainerBody = styled.section`
  background: ${({ theme }) => theme.colors.gray[500]};
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  p {
    font-size: 1.25rem;
    line-height: 1.35;
    color: ${({ theme }) => theme.colors.white};
    strong {
      font-weight: 600;
    }
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: none;
  }
`
