import styled from 'styled-components'

export const Container = styled.main`
  background: url('/assets/images/BG-1-Home.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1000px;
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    background-size: 2000px;
    min-height: 80vh;
  }
`

export const ContentContainer = styled.div`
  max-width: 75%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};

  .button-now-services {
    margin-top: 2rem;
    width: 70%;
    button {
      width: 100%;
      font-size: 10px;
      padding: 1rem;
      font-weight: 600;
      border-radius: 2.5px;
    }
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.md}) {
    justify-content: center;
    max-width: 1120px;
    margin: 30px auto 0 auto;
    height: 100%;

    .button-now-services {
      max-width: 330px;
      button {
        font-size: 0.9rem;
        width: 100%;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    padding: 30px;
    max-height: 70vh;
  }
`

export const ContentImage = styled.img`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 90%;
  @media (min-width: 768px) {
    width: 400px;
    margin-bottom: 3.2rem;
  }
  @media (min-width: 1024px) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 1368px) {
    margin-bottom: 6rem;
  }
`
export const ContentTitle = styled.h1`
  font-size: 26px;
  font-weight: 500;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    max-width: 500px;
    margin-bottom: 0rem;
  }

  @media (min-width: 1368px) {
    font-size: 3rem;
    max-width: 500px;
    margin-bottom: 1rem;
  }
`

export const ContentParagraph = styled.p`
  font-size: 12px;
  margin-top: 1.2rem;
  max-width: 430px;

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.md}) {
    font-size: 0.9rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0rem;
  }
  @media (min-width: 1368px) {
    font-size: 1.275rem;
    margin-bottom: 1rem;
    max-width: 480px;
  }
`
