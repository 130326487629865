import { ThemeProvider } from 'styled-components'
import { Router } from './router'
import { GlobalStyles } from './styles/global'
import { themes } from './styles/variables'

function App() {
  return (
    <ThemeProvider theme={themes}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  )
}

export default App
