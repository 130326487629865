import { PartnersSlide } from 'components/layout/PartnersSlide'
import { SubMenu } from 'components/layout/Submenu'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const PartnersPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/2-Institucional-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <SubMenu current="Sócios" />
        <Styled.ContentContainer>
          <PartnersSlide />
        </Styled.ContentContainer>
      </Styled.Container>
    </>
  )
}
