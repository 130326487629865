import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Slider = styled(Swiper)`
  --swiper-theme-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.25));
  align-items: stretch;
  .swiper-slide {
    text-align: center;

    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, #ffffff 100%);
    height: auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
`

export const Partner = styled.div`
  padding: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};

  h3 {
    font-size: 12px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 800;
  }
  h4 {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1rem;
    font-weight: 600;
  }
  p {
    font-size: 10px;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 14px;
    }
  }
`
