import styled from 'styled-components'
import { fontSize, FontSizeProps, margin, MarginProps } from 'styled-system'

type TitleOutlinedProps = { outColor?: string } & FontSizeProps & MarginProps

export const TitleOutlined = styled.h1<TitleOutlinedProps>`
  position: relative;
  margin-top: 1rem;
  line-height: 1.5;
  font-weight: 600;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  &::after {
    content: '';
    background: ${({ theme, outColor }) =>
      outColor
        ? outColor === 'primary'
          ? theme.colors.primary
          : theme.colors.tertiary
        : theme.colors.primary};
    width: 15%;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 0;
  }

  ${fontSize}
  ${margin}
`

TitleOutlined.defaultProps = {
  outColor: 'primary',
}
