import styled, { css } from 'styled-components'

import {
  alignItems,
  AlignItemsProps,
  borderRadius,
  BorderRadiusProps,
  buttonStyle,
  ButtonStyleProps,
  color,
  ColorProps,
  flex,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  WidthProps,
  width,
} from 'styled-system'

type ButtonProps = ColorProps &
  SpaceProps &
  FontSizeProps &
  ButtonStyleProps &
  LayoutProps &
  FlexboxProps &
  AlignItemsProps &
  BorderRadiusProps & { size?: 'sm' | 'md' | 'lg' } & {
    fullWidth?: boolean
  } & GridProps &
  TextAlignProps &
  WidthProps

export const Button = styled.button<ButtonProps>`
  ${color}
  ${fontSize}
  ${buttonStyle}
  ${borderRadius}
  ${alignItems}
  ${flex}
  ${layout}
  ${grid}
  ${space}
  ${textAlign}
  ${width}
  
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `} 

  border: none;
  ${({ size }) =>
    size == 'sm'
      ? css`
          font-size: 14px;
          padding: 7px 15px;
        `
      : size == 'md'
      ? css`
          font-size: 16px;
          padding: 9px 20px;
        `
      : css`
          font-size: 22px;
          padding: 15px 30px;
        `}
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    &:disabled {
      opacity: 0.6;
    }
  }
`

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
  borderRadius: 5,
}
