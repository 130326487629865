import { SubMenu } from 'components/layout/Submenu'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const AffiliatedPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/2-Institucional-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <SubMenu current="Filiais" />
        <div className="content-container">
          <Styled.ContentContainer>
            <Styled.ContentMainImage
              src="/assets/images/affiliated-map.svg"
              alt="Mapa do Brasil Imagem"
              draggable={false}
              style={{ width: '100%' }}
            />
          </Styled.ContentContainer>
          <Styled.ContentBody>
            <p>
              As filiais são todas próprias e estão instaladas de acordo com a{' '}
              <strong>NR-17</strong>, possuem capacidade para suportar equipes de cobrança com
              grande quantidade de pessoas, além disso, o serviço de advocacia de apoio é realizado
              integralmente por nossas filiais.
            </p>

            <Styled.List>
              {Cities.map((city, index) => (
                <li key={String(index + 1)}>{city.name}</li>
              ))}
            </Styled.List>
          </Styled.ContentBody>
        </div>
      </Styled.Container>
      <Styled.ContainerBody>
        <p>
          As filiais são todas próprias e estão instaladas de acordo com a <strong>NR-17</strong>,
          possuem capacidade para suportar equipes de cobrança com grande quantidade de pessoas,
          além disso, o serviço de advocacia de apoio é realizado integralmente por nossas filiais.
        </p>
        <Styled.List>
          {Cities.map((city, index) => (
            <li key={String(index + 1)}>{city.name}</li>
          ))}
        </Styled.List>
      </Styled.ContainerBody>
    </>
  )
}

const Cities = [
  {
    name: 'São Paulo - SP',
  },
  {
    name: 'Rio de Janeiro - RJ',
  },
  {
    name: 'Belo Horizonte - MG',
  },
  {
    name: 'Curitiba - PR',
  },
  {
    name: 'Florianópolis - SC',
  },
  {
    name: 'Porto Alegre - RS',
  },
  {
    name: 'Brasília - DF',
  },
  {
    name: 'Cuiabá - MT',
  },
  {
    name: 'Campo Grande - MS',
  },
  {
    name: 'Goiânia - GO',
  },
  {
    name: 'Vitória - ES',
  },
  {
    name: 'Salvador - BA',
  },
  {
    name: 'Fortaleza - CE',
  },
  {
    name: 'Manaus - MA',
  },
  {
    name: 'Joinville - SC',
  },
  {
    name: 'Dourados - MS',
  },
  {
    name: 'Cascavel - PR',
  },
  {
    name: 'Maringá - PR',
  },
  {
    name: 'Recife - PE',
  },
  {
    name: 'Palmas - TO',
  },

  {
    name: 'São Luíz - MA',
  },
  {
    name: 'Belém - PA',
  },
  {
    name: 'Aracajú - SE',
  },
  {
    name: 'Rio Branco - AC',
  },
  {
    name: 'Porto Velho - RO',
  },
  {
    name: 'Maceió - AL',
  },
  {
    name: 'Teresina - PI',
  },
  {
    name: 'Natal - RN',
  },
]
