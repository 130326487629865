import styled from 'styled-components'

export const Container = styled.main`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('/assets/images/BG-5-RespSocial.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1200px;
  padding: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    background-size: 100%;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    background-size: 2000px;
    .content-container {
      max-width: 1024px;
      margin: 0 auto;
    }
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.xxl}) {
    background: none;
    background-size: 3000px;
  }
`

export const ContainerSubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 1rem;
  line-height: 1.5;
  strong {
    font-weight: 600;
  }
`

export const ContainerBody = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[500]};
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: none;
  }
`

export const CertifiersContainer = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;
`

export const CertifiersContainerDesktop = styled.div`
  margin-top: 1rem;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url('/assets/images/noise.png') no-repeat;
  padding: 1rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`

export const CertifiedItem = styled.div`
  text-align: center;
  h4 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    margin-top: 0.5rem;
  }
  p {
    color: ${({ theme }) => theme.colors.white};
  }
  img {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    img {
      height: 200px;
    }
  }
`

export const DesktopAditionalInfos = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: block;
    margin-top: 0rem;
  }

  .social-contribute {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;

    .social-contribute-item {
      background: ${({ theme }) => theme.colors.white};
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 1rem;
      div {
        margin-left: 1rem;
        p {
          font-weight: 500;
          line-height: 1.5;
        }
        h4 {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
`
