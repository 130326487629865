import axios, { AxiosInstance } from 'axios'

export class GenericService {
  protected readonly http: AxiosInstance
  constructor(relativePath = '') {
    const baseURL = `https://portalprivacidade.goesnicoladelli.net${relativePath}`
    // const baseURL = `http://portalprivacidadedev.goesnicoladelli.net${relativePath}`
    // const baseURL = `http://localhost:8000${relativePath}`
    this.http = axios.create({
      baseURL,
    })
  }
}
