import styled from 'styled-components'

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  color: ${({ theme }) => theme.colors.gray[500]};
  padding: 2rem 1rem;
`
export const FooterContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 1250px;
    margin: 0 auto;
  }
  img.goes-logo {
    max-width: 60%;
    /* margin-left: 2rem; */
  }
`

export const FooterInfosContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    gap: 1rem;
  }
`

export const FooterAddressInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  line-height: 1.5;
  p {
    font-weight: 500;
    font-size: 0.875rem;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    margin-top: 0;
    align-items: start;
    justify-content: start;
  }
`

export const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    align-items: start;
  }
`
export const FooterSocialsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  @media (min-width: 1024px) {
    display: none;
  }
`

export const FooterSocialsInfoDesktop = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
`

export const FooterSocialItem = styled.a`
  margin: 0;
  padding: 0;
  img {
    width: 24px;
  }
`

export const FooterNavLinks = styled.nav`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 0;
  margin-top: 2rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    margin-top: -2.5rem;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }
`

export const FooterNavItem = styled.div`
  h4 {
    font-weight: 800;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    li a {
      color: ${({ theme }) => theme.colors.gray[500]};
      text-decoration: none;
      font-weight: 500;
      line-height: 1.5;
      transition: 200ms all ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
      }
    }
  }
`
