import styled from 'styled-components'

export const ChatPopupContainer = styled.button`
  background: linear-gradient(180deg, #f03a50 0%, #811220 100%);
  border-radius: 9px;
  padding: 1rem;
  border: none;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 8;
  justify-content: center;
  img {
    margin-bottom: -10px;
    margin-left: -5px;
  }
`

interface ChatPopup {
  isOpen: boolean
}
export const ChatPopup = styled.div<ChatPopup>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  bottom: 2rem;
  z-index: 10;
  right: 1rem;
  width: 300px;
  min-height: 400px;
  background: ${({ theme }) => theme.colors.gray[400]};
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 10px 10px 0 0;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    button {
      border: none;
      background: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
    }
  }
  main form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    button {
      align-self: start;
    }
  }
`
