import styled from 'styled-components'

export const HeaderContainer = styled.header`
  height: 105px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Header = styled.div`
  max-width: 1280px;
  .popover-desktop {
    position: absolute;
    right: 1rem;
  }
  @media (min-width: 1024px) {
    margin: 0 auto;
    .popover-desktop {
      right: 0rem;
      position: relative;
      max-width: 1280px;
      margin: 0 auto;
    }
  }
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderLogo = styled.h1`
  img {
    width: 15rem;
  }
  img.mobile {
    display: block;
  }
  img.desktop {
    display: none;
    width: 54px;
  }
  @media (min-width: 1024px) {
    img.mobile {
      display: none;
    }
    img.desktop {
      display: block;
    }
  }
`

export const HeaderButton = styled.button`
  display: flex;
  @media (min-width: 1024px) {
    display: none;
  }
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.725rem;
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  font-size: 18px;
  border: 0;
  cursor: pointer;
  transition: background 200ms ease-in-out;
  &:focus {
    background: ${({ theme }) => theme.colors.primary_hover};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary_hover};
  }
`

interface HeaderNavbarProps {
  isOpen: boolean
}

export const HeaderNavbar = styled.nav<HeaderNavbarProps>`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 200ms ease-in-out;
  display: flex;
  @media (min-width: 1024px) {
    display: none;
  }
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  margin: 0;
  ul {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 1rem;
    li {
      a {
        color: ${({ theme }) => theme.colors.white};
        font-weight: bold;
        font-size: 16px;
        text-decoration: none;
        transition: all 200ms ease-in-out;
        &:hover {
          color: ${({ theme }) => theme.colors.primary};
          text-decoration: underline;
        }
      }
    }
  }
`

export const DesktopNavbar = styled.nav`
  display: none;
  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    .want-trade-btn {
      max-width: 180px;
    }
  }
  ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-right: 2rem;
    margin-left: 1rem;
    align-items: center;
    gap: 1rem;
    li {
      a {
        color: ${({ theme }) => theme.colors.secondary};
        font-weight: bold;
        font-size: 14px;

        text-decoration: none;
        transition: all 200ms ease-in-out;
        &:hover {
          color: ${({ theme }) => theme.colors.primary};
          text-decoration: underline;
        }
      }
    }
  }
`
