import styled from 'styled-components'

export const SubmenuHeader = styled.header`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2rem;

  nav {
    width: 100%;
    ul {
      display: flex;
      gap: 2rem;
      justify-content: center;
      li {
        a {
          color: ${({ theme }) => theme.colors.white};
          font-weight: 500;
          text-decoration: none;
          position: relative;
          &.active::after {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            background: ${({ theme }) => theme.colors.primary};
            bottom: -4px;
            left: 0;
          }
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 3rem;
    nav {
      display: flex;
      align-items: end;
      justify-content: end;
      /* margin-left: 2rem; */
      ul {
        width: 100%;
        max-width: 46.5%;
        justify-content: start;
        li {
          a {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`
