import { useState } from 'react'
import * as Styled from './styles'

export const FAQ = (): JSX.Element => {
  const [open, setOpen] = useState<number>(0)

  const handleClickOpen = (id: number) => {
    if (open === id) return setOpen(0)
    setOpen(id)
  }

  return (
    <Styled.QuestionContainer>
      {FAQQuestions.map((question) => (
        <Styled.Question key={String(question.id)} className={open === question.id ? 'active' : ''}>
          <Styled.QuestionHeader onClick={() => handleClickOpen(question.id)}>
            <h4>{question.title}</h4>
            <button>
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 9.20044L0.303847 0.200439L10.6962 0.20044L5.5 9.20044Z"
                  fill="white"
                />
              </svg>
            </button>
          </Styled.QuestionHeader>
          <Styled.QuestionBody>
            {question.content.split('\n').map((content, index) => (
              <p
                key={String(index + 1)}
                dangerouslySetInnerHTML={{
                  __html: content.replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>'),
                }}
              />
            ))}
          </Styled.QuestionBody>
        </Styled.Question>
      ))}
    </Styled.QuestionContainer>
  )
}

const FAQQuestions = [
  {
    id: 1,
    title: 'O Que é LGPD?',
    content: `A LGPD, ou **Lei Geral de Proteção de Dados Pessoais** (13.709/2018), é uma legislação brasileira que visa proteger os direitos fundamentais de privacidade e liberdade dos cidadãos em relação ao tratamento de seus dados pessoais.\n
    A LGPD estabelece regras e princípios para o tratamento (Coleta, Armazenamento, Alteração, exclusão etc.) de dados pessoais por organizações públicas e privadas.\n
    Além disso, a LGPD estabelece obrigações e responsabilidades para os controladores e operadores de dados, visando promover a transparência e a segurança no tratamento de dados pessoais no ambiente físico e digital. A legislação busca equilibrar o uso legítimo dos dados com a proteção da privacidade e a garantia dos direitos individuais.`,
  },
  {
    id: 2,
    title: 'Quem é o Titular de Dados?',
    content: `O titular de dados pessoais é a pessoa física/natural a quem se referem os dados pessoais que estão sendo coletados, armazenados, alterados, excluídos etc. Em outras palavras, o titular de dados é a pessoa a quem esses dados se referem/pertencem e que é o objeto da informação em questão.`,
  },
  {
    id: 4,
    title: 'O Que é Controlador de Dados Pessoais?',
    content: `O controlador de dados pessoais é uma organização ou indivíduo que determina as finalidades, meios e proteção pelos quais os dados pessoais são coletados, armazenados, alterados, excluídos etc.\n
    Em muitos casos, o controlador é responsável por garantir que o tratamento dos dados pessoais esteja em conformidade com as leis de proteção de dados. Isso inclui a obtenção de consentimento, quando necessário, e a implementação de medidas de segurança para proteger a privacidade e a integridade dos dados.`,
  },
  {
    id: 5,
    title: 'O Que é Operador de Dados Pessoais?',
    content: `O operador de dados pessoais é uma organização ou indivíduo que processa dados pessoais em nome do controlador de dados. Enquanto o controlador é responsável por determinar os fins e meios do tratamento de dados, o operador executa as operações específicas de processamento conforme instruções do controlador e da LGPD.`,
  },
  {
    id: 6,
    title: 'O Que é Encarregado de Dados Pessoais?',
    content: `O encarregado pelo tratamento de dados pessoais possui a função de atuar como canal de comunicação entre instituição, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).`,
  },
  {
    id: 7,
    title: 'O Que São os Direitos do Titular de Dados Pessoais?',
    content: `O direito a obter do controlador, a qualquer momento e mediante requisição as informações durante o tratamento dos dados pessoais nos termos da lei.\n
    Da mesma forma, todo titular tem assegurado o direito a obter do controlador as informações sobre o tratamento e consequências sobre a sua negação ao fornecê-la.`,
  },
  {
    id: 8,
    title: 'O Que é Tratamento de Dados Pessoais?',
    content: `Toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.\n
    Independentemente se os dados são físicos ou lógicos, se realizado pelo controlador ou operadores.`,
  },
]
