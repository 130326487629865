import { ContactForm } from 'components/layout/ContactForm'
import { TitleOutlined } from 'components/reusables/TitleOutlined'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const ContactPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/4-Contato-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <div className="content-container">
          <div>
            <TitleOutlined>Contato</TitleOutlined>
            <Styled.ContainerSubTitle>
              Preencha o formulário abaixo para{' '}
              <strong>conhecer nosso portfólio de clientes</strong> e entrar em contato.
            </Styled.ContainerSubTitle>
          </div>
          <Styled.ContentContainer>
            <ContactForm />
            <Styled.ContactContainerDesktop>
              <Styled.ContactItem>
                <svg
                  width="26"
                  height="19"
                  viewBox="0 0 26 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.3">
                    <path
                      d="M13.6989 10.4193C13.4908 10.5665 13.2454 10.64 13 10.64C12.7545 10.64 12.5092 10.5665 12.3011 10.4193L7.87876e-05 1.71782L0 17.86C7.87876e-05 18.4896 0.529138 19 1.18181 19L24.8182 18.9999C25.4709 18.9999 26 18.4895 26 17.86V1.71775L13.6989 10.4193Z"
                      fill="white"
                    />
                    <path d="M13 8.08637L24.431 7.59579e-05L1.56873 0L13 8.08637Z" fill="white" />
                  </g>
                </svg>

                <div>
                  <p>goesnicoladelli@goesnicoladelli.com.br | negociador@goesnicoladelli.com.br</p>
                </div>
              </Styled.ContactItem>
              <Styled.ContactItem>
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.7641 15.9263C20.9452 14.4483 18.1013 12.8457 17.9761 12.7756C17.6107 12.577 17.2294 12.4719 16.8725 12.4719C16.3419 12.4719 15.9076 12.704 15.6445 13.126C15.2285 13.601 14.7126 14.1563 14.5874 14.2423C13.6183 14.87 12.8598 14.7987 12.0204 13.9974L7.33598 9.52485C6.50195 8.72862 6.42527 7.99546 7.07822 7.07541C7.16958 6.95509 7.75116 6.46217 8.24872 6.06463C8.56602 5.88436 8.78381 5.61648 8.87924 5.28787C9.00608 4.85062 8.91269 4.33628 8.61333 3.83517C8.54278 3.71992 6.86329 1.00454 5.31595 0.223102C5.0272 0.0770928 4.70174 0 4.37547 0C3.83794 0 3.33221 0.20013 2.95211 0.562622L1.91701 1.55042C0.279939 3.11291 -0.312652 4.8841 0.154732 6.81454C0.544627 8.42336 1.68087 10.1354 3.53246 11.9027L9.52975 17.6282C11.8732 19.8654 14.1073 21 16.1702 21C17.6874 21 19.1022 20.3844 20.3734 19.1708L21.4081 18.183C22.037 17.583 22.1797 16.6758 21.7641 15.9263Z"
                    fill="white"
                  />
                </svg>

                <div>
                  <p>
                    0800-710-2300 | {'(48)'} 3431-8888 | {'(48)'} 3461-2300
                  </p>
                </div>
              </Styled.ContactItem>
              <Styled.ContactItem>
                <svg
                  className="pin"
                  width="15"
                  height="23"
                  viewBox="0 0 15 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M7.49798 0C2.14159 0 -0.897288 4.7955 0.235602 9.89309C1.04244 13.5231 5.91463 23 7.49803 23C8.97748 23 13.941 13.52 14.7605 9.89309C15.9008 4.84519 12.8858 0 7.49798 0ZM7.50028 10.5073C5.95389 10.5073 4.70059 9.20591 4.70059 7.60014C4.70059 5.99437 5.95389 4.69303 7.50028 4.69303C9.04666 4.69303 10.3 5.99437 10.3 7.60014C10.3 9.20591 9.04666 10.5073 7.50028 10.5073Z"
                    fill="white"
                  />
                </svg>

                <div>
                  <p>
                    Rua Almirante Tamandaré - Nº 114 - Santa Bárbara - CEP 88.804-290 - Criciúma SC
                  </p>
                </div>
              </Styled.ContactItem>
            </Styled.ContactContainerDesktop>
          </Styled.ContentContainer>
        </div>
      </Styled.Container>
      <Styled.ContainerBody>
        <Styled.ContactContainer>
          <Styled.ContactItem>
            <svg
              width="26"
              height="19"
              viewBox="0 0 26 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <path
                  d="M13.6989 10.4193C13.4908 10.5665 13.2454 10.64 13 10.64C12.7545 10.64 12.5092 10.5665 12.3011 10.4193L7.87876e-05 1.71782L0 17.86C7.87876e-05 18.4896 0.529138 19 1.18181 19L24.8182 18.9999C25.4709 18.9999 26 18.4895 26 17.86V1.71775L13.6989 10.4193Z"
                  fill="white"
                />
                <path d="M13 8.08637L24.431 7.59579e-05L1.56873 0L13 8.08637Z" fill="white" />
              </g>
            </svg>

            <div>
              <p>goesnicoladelli@goesnicoladelli.com.br</p>
              <p>negociador@goesnicoladelli.com.br</p>
            </div>
          </Styled.ContactItem>
          <Styled.ContactItem>
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.7641 15.9263C20.9452 14.4483 18.1013 12.8457 17.9761 12.7756C17.6107 12.577 17.2294 12.4719 16.8725 12.4719C16.3419 12.4719 15.9076 12.704 15.6445 13.126C15.2285 13.601 14.7126 14.1563 14.5874 14.2423C13.6183 14.87 12.8598 14.7987 12.0204 13.9974L7.33598 9.52485C6.50195 8.72862 6.42527 7.99546 7.07822 7.07541C7.16958 6.95509 7.75116 6.46217 8.24872 6.06463C8.56602 5.88436 8.78381 5.61648 8.87924 5.28787C9.00608 4.85062 8.91269 4.33628 8.61333 3.83517C8.54278 3.71992 6.86329 1.00454 5.31595 0.223102C5.0272 0.0770928 4.70174 0 4.37547 0C3.83794 0 3.33221 0.20013 2.95211 0.562622L1.91701 1.55042C0.279939 3.11291 -0.312652 4.8841 0.154732 6.81454C0.544627 8.42336 1.68087 10.1354 3.53246 11.9027L9.52975 17.6282C11.8732 19.8654 14.1073 21 16.1702 21C17.6874 21 19.1022 20.3844 20.3734 19.1708L21.4081 18.183C22.037 17.583 22.1797 16.6758 21.7641 15.9263Z"
                fill="white"
              />
            </svg>

            <div>
              <p>0800-710-2300</p>
              <p>{'(48)'} 3431-8888</p>
              <p>{'(48)'} 3461-2300</p>
            </div>
          </Styled.ContactItem>
          <Styled.ContactItem>
            <svg
              className="pin"
              width="15"
              height="23"
              viewBox="0 0 15 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M7.49798 0C2.14159 0 -0.897288 4.7955 0.235602 9.89309C1.04244 13.5231 5.91463 23 7.49803 23C8.97748 23 13.941 13.52 14.7605 9.89309C15.9008 4.84519 12.8858 0 7.49798 0ZM7.50028 10.5073C5.95389 10.5073 4.70059 9.20591 4.70059 7.60014C4.70059 5.99437 5.95389 4.69303 7.50028 4.69303C9.04666 4.69303 10.3 5.99437 10.3 7.60014C10.3 9.20591 9.04666 10.5073 7.50028 10.5073Z"
                fill="white"
              />
            </svg>

            <div>
              <p>Rua Almirante Tamandaré - Nº 114 - Santa Bárbara - CEP 88.804-290 - Criciúma SC</p>
            </div>
          </Styled.ContactItem>
        </Styled.ContactContainer>
      </Styled.ContainerBody>
    </>
  )
}
