import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;
`

export const SubTitle = styled.p`
  font-size: 20px;
  line-height: 1.3;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
`
