import { GenericService } from './GenericService'

// interface sendPrivacyRequestDTO {
//   nome: string
//   contrato?: string
//   email: string
//   cpf: string
//   vinculo?: string
//   tipo: string
//   descricao: string
//   toggle: string
//   arquivo?: File
// }

interface sendContactFormDTO {
  nome: string
  email: string
  telefone: string
  cidade: string
  mensagem: string
}

class FormsService extends GenericService {
  constructor() {
    super('/api')
  }

  async sendPrivacyRequest(formData: FormData) {
    return await this.http.post('/privacy-request/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async sendContactForm(data: sendContactFormDTO) {
    return await this.http.post('/contact/send', {
      ...data,
    })
  }

  async getPrivacyRequestTypes() {
    return await this.http.get<
      {
        id: number
        descricao: string
      }[]
    >('get-types')
  }

  async getPrivacyLinkTypes() {
    return await this.http.get<
      {
        id: number
        descricao: string
      }[]
    >('get-links-with-company')
  }
}

export default new FormsService()
