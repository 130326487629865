import styled from 'styled-components'

interface WorkHereContainerProps {
  isOpen: boolean
}

export const WorkHereContainer = styled.div<WorkHereContainerProps>`
  position: absolute;
  top: 60px;
  right: 1px;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 1rem;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  z-index: 9;
  min-width: 200px;
  transition: all 300ms ease-in-out;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    li {
      a {
        display: flex;
        align-items: center;
        border: 0;
        width: 100%;
        text-align: left;
        padding: 0.625rem;
        text-decoration: none;
        border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
        color: ${({ theme }) => theme.colors.gray[500]};
        transition: background-color 200ms ease-in-out;
        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[300]};
        }
        img {
          margin-right: 0.625rem;
        }
      }
    }
  }
`
export const WorkHereCloseButton = styled.button`
  border: none;
  text-align: right;
  background-color: transparent;
  cursor: pointer;
`
