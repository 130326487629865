import { TitleOutlined } from 'components/reusables/TitleOutlined'
import styled from 'styled-components'

export const Container = styled.main`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('/assets/images/BG-6-Privacidade.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1200px;
  padding: 1rem;
  @media (min-width: 768px) {
    background-size: 100%;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    .content-container {
      max-width: 1120px;
      margin: 0 auto;
      margin-bottom: 4rem;
    }
  }
`
export const ContentContainer = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 100%;
  }
`
export const ContentContainerHeader = styled.header`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 20px 20px 0 0;
  h1 {
    font-size: 30px;
    font-weight: 600;
  }
`
export const ContentContainerBody = styled.div`
  padding: 1rem;
  p {
    font-size: 18px;
    margin-bottom: 1.675rem;
  }
`
export const ContentContainerTitle = styled(TitleOutlined)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 1.5rem;
`
export const ContainerBody = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[500]};
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }

  .text-sm {
    font-size: 14px;
  }

  .text-md {
    font-size: 16px;
  }

  .text-space {
    line-height: 25px;
  }

  .table {
    width: 100%;
    border: 1px solid #ddd;
  }

  .table tbody tr td {
    border: 1px solid #ddd;
    padding: 8px;
  }
`