import { useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import { ModalCookieConsent } from '../ModalCookieConsent'

const CookieConsentModal = (): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <ModalCookieConsent modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <CookieConsent
        location="bottom"
        buttonText="ACEITAR"
        cookieName="CookieConsent"
        style={{
          background: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          color: '#000',
          width: '100%',
          padding: '1rem',
          boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',
        }}
        buttonStyle={{
          backgroundColor: '#CA1F34',
          fontSize: '16px',
          borderRadius: '4px',
          color: '#fff',
        }}
        expires={150}
      >
        A Góes & Nicoladelli utiliza cookies como medidas técnicas necessárias para navegação neste
        Website. Você concorda com o Aviso de Uso de 
        {' '}<Link to="/aviso-cookies" style={{ color: '#CA1F34' }}>Cookies</Link> 
        {' '}e{' '}
        <Link to="/aviso-privacidade" style={{ color: '#CA1F34' }}>Privacidade</Link>?
      </CookieConsent>
    </>
  )
}

export { CookieConsentModal }

