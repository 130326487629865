import { useEffect, useRef } from 'react'
import { FiX } from 'react-icons/fi'
import * as Styled from './styles'
import { Link } from 'react-router-dom'

interface WorkHereProps {
  isOpenWorkHere: boolean
  handleRequestCloseWorkHere: () => void
}

export const WorkHere = ({
  isOpenWorkHere,
  handleRequestCloseWorkHere,
}: WorkHereProps): JSX.Element => {
  const workHereRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOpenMenus = (e: any) => {
      if (workHereRef.current && isOpenWorkHere && !workHereRef.current.contains(e.target)) {
        handleRequestCloseWorkHere()
        document.body.classList.remove('no-scrollbar')
      }
    }

    document.addEventListener('mousedown', closeOpenMenus)
  }, [handleRequestCloseWorkHere])
  return (
    <Styled.WorkHereContainer ref={workHereRef} isOpen={isOpenWorkHere}>
      <Styled.WorkHereCloseButton onClick={handleRequestCloseWorkHere}>
        <FiX />
      </Styled.WorkHereCloseButton>
      <ul>
        {LinkItems.map((link, index) => (
          <li key={String(index + 1)}>
            {link.isBlank ? (
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.name}
              </a>
            ) : (
              <Link to={link.url}>{link.name}</Link>
            )}
          </li>
        ))}
      </ul>
    </Styled.WorkHereContainer>
  )
}

const LinkItems = [
  {
    name: 'Nossas vagas',
    url: 'https://platform.senior.com.br/hcmrs/hcm/curriculo/?tenant=goesnicoladellicombr&tenantdomain=goesnicoladelli.com.br#!/vacancies/list',
    isBlank: true,
  },
  {
    name: 'Relatório igualdade salarial',
    url: '/relatorio-gualdade-salarial',
    isBlank: false,
  },
]
