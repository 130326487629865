import styled from 'styled-components'

export const Container = styled.main`
  background: url('/assets/images/BG-2-Institucional.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1000px;
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.md}) {
    background-size: 2000px;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    min-height: 80vh;
    background: none;
  }
`

export const ContentContainer = styled.div`
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url('/assets/images/noise.png') no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align: center;
    font-weight: 700;
    margin-top: 0.675rem;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      url('/assets/images/noise.png') no-repeat;
    background-size: cover;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
`
