import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { reset } from 'styled-reset'

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${reset}
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Bai Jamjuree', sans-serif;
  }

  body.no-scrollbar {
    overflow: hidden;
  }

  strong {
    font-weight: 600;
  }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #8E1726;
  border-radius: 2px;
  transition: all 200ms ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #CA1F34;
}
`
