import styled from 'styled-components'

export const Form = styled.form`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 3rem;
  input[type='radio'] {
    appearance: none;
    display: grid;
    place-content: center;
    &::before {
      content: '';
      background: transparent;
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.colors.white};
      display: inline-block;
      width: 1rem;
      height: 1rem;
      position: relative;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
    &:checked::before {
      background-color: ${({ theme }) => theme.colors.gray[300]};
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.gray[500]};
    }
  }
  .select-solicitation-label {
    margin-top: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 100%;
  }
`
export const FormGroup = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    &:not(.accept-form) {
      max-width: 50%;
    }
  }
`

export const FormLabel = styled.label`
  display: flex;
  gap: 0.625rem;
`

export const RadioLabel = styled.label`
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1em;
  input {
    color: ${({ theme }) => theme.colors.gray[500]};
  }
`

export const FormInput = styled.input`
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 1rem;
`

export const FormSelect = styled.select`
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 1rem;
`

export const CheckboxLabel = styled.label`
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding-right: 2.2rem;

  input[type='checkbox']:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='checkbox'] {
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 5px;
    margin-right: 10px;
  }

  input[type='checkbox']:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary}
      url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
      2px 2px no-repeat;
  }
`

export const CheckboxLabelAux = styled.label`
  display: block;
  grid-template-columns: repeat(2, auto);
  padding-right: 2.2rem;
  line-height: 25px;

  input[type='checkbox']:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='checkbox'] {
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 5px;
    margin-right: 10px;
  }

  input[type='checkbox']:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary}
      url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
      2px 2px no-repeat;
  }
`

export const Divisor = styled.hr`
  margin-top: 4rem;
  margin-bottom: 2rem;
  border-color: ${({ theme }) => theme.colors.tertiary};
`
