import styled from 'styled-components'

export const FooterCopyright = styled.div`
  height: 100%;
  min-height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  p {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
  }
`
