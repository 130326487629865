import { SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import { Pagination } from 'swiper'

import * as Styled from './styles'

export const ServicesSlide = (): JSX.Element => {
  return (
    <Styled.Slider
      slidesPerView={1}
      spaceBetween={30}
      grabCursor={true}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
    >
      {Services.map((service, index) => (
        <SwiperSlide key={String(index + 1)}>
          <Styled.ServiceTitle>{service.title}</Styled.ServiceTitle>
          {service.description.split('\n').map((description, index) => (
            <Styled.ServiceDescription
              key={String(index + 1)}
              dangerouslySetInnerHTML={{
                __html: description.replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>'),
              }}
            />
          ))}
        </SwiperSlide>
      ))}
    </Styled.Slider>
  )
}

const Services = [
  {
    title: 'Jurídico',
    description: `
    O **Escritório Góes e Nicoladelli Advogados Associados** conta com uma equipe de Advogados, altamente capacitados, para atuar em ações em massa, de natureza cível, cujos clientes são, em geral, Bancos, Securitizadoras e Instituições Financeiras, que exigem gerenciamento específico dos processos, com as devidas cautelas, visando, especialmente, a redução e limpeza de suas carteiras, com gestão eficiente, agindo de forma preventiva, mitigando possíveis riscos e buscando melhor solução jurídica-econômica, **em todas as instâncias do Poder Judiciário, em todos os Estados,** com o compromisso de adequar sua estrutura Jurídica de acordo com as necessidades do cliente.
    `,
  },
  {
    title: 'Jurídico Ativo',
    description: `
    Departamento Jurídico Ativo é o seguimento do Escritório que **atua nas ações em que o cliente é Autor, como Ações de Busca e Apreensão, Reintegração de Posse, Execução, entre outras.**\n
    É dividido em setores, os quais são subdivididos, de acordo com sua natureza e necessidade, para realizar o trabalho em esteira de produção, que se comunicam entre si em tempo real, via sistema in web e arquivo digital, mantendo, também, o sistema do cliente alimentado de acordo com as informações do processo diariamente.
    `,
  },
  {
    title: 'Jurídico Passivo',
    description: `
    O Departamento Jurídico Passivo, cuja **atuação se dá nos processos onde o cliente é o Requerido, ou seja, ações de Revisão de Contrato, Indenizatórias,** Consignação em Pagamento, entre outras, assim como o Ativo, mantém a mesma responsabilidade com relação ao arquivo digital e sistema do cliente e também é dividido em setores, com suas devidas peculiaridades.
    `,
  },
]
