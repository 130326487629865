// import GoesBlackLogo from 'assets/images/goes-logo-black.svg'
// import GoesBlackLogoSmall from 'assets/images/goes-logo-small-black.svg'
import { Button } from 'components/reusables/Button'
import { useEffect, useState } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import * as Styled from './styles'
import { WantTrade } from './WantTrade'
import { WorkHere } from '../WorkHere'
import './style.css'

const NavLinks = [
  {
    name: 'HOME',
    path: '/',
  },
  {
    name: 'INSTITUCIONAL',
    path: '/institucional/sobre',
  },
  {
    name: 'SERVIÇOS',
    path: '/servicos',
  },
  {
    name: 'CONTATO',
    path: '/contato',
  },
  {
    name: 'RESPONSABILIDADE SOCIAL E AMBIENTAL',
    path: '/responsabilidade-social-ambiental',
  },
  {
    name: 'PORTAL DE PRIVACIDADE',
    path: 'privacidade',
  },
]

export const Navbar = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenWorkHere, setIsOpenWorkHere] = useState(false)
  const [wantTradeIsOpen, setWantTradeIsOpen] = useState(false)
  const [workHereIsOpen, setWorkHereIsOpen] = useState(false)

  const handleOpenMenu = () => {
    setIsOpen(!isOpen)
    wantTradeIsOpen && setWantTradeIsOpen(false)
  }
  const handleWantTrade = () => {
    setIsOpen(!isOpen)
    setWantTradeIsOpen(!wantTradeIsOpen)
  }
  const handleWorkHere = () => {
    setIsOpenWorkHere(!isOpenWorkHere)
    setWorkHereIsOpen(!workHereIsOpen)
  }
  const handleRequestClose = () => {
    setWantTradeIsOpen(false)
  }
  const handleRequestCloseWorkHere = () => {
    setWorkHereIsOpen(false)
  }
  useEffect(() => {
    if (isOpen || wantTradeIsOpen) {
      document.body.classList.add('no-scrollbar')
    } else {
      document.body.classList.remove('no-scrollbar')
    }
  }, [isOpen, wantTradeIsOpen])

  return (
    <Styled.HeaderContainer>
      <Styled.Header>
        <Styled.HeaderLogo>
          <Link to="/">
            <img
              className="mobile"
              src="/assets/images/goes-logo-black.svg"
              alt="Góes &amp; Nicoladelli Logo"
              draggable={false}
            />
            <img
              className="desktop"
              src="/assets/images/goes-logo-small-black.svg"
              alt="Góes &amp; Nicoladelli Logo"
              draggable={false}
            />
          </Link>
        </Styled.HeaderLogo>
        <Styled.HeaderButton onClick={handleOpenMenu}>
          {isOpen ? <FiX /> : <FiMenu />}
        </Styled.HeaderButton>
        <MobileNavbar
          handleOpenMenu={handleOpenMenu}
          handleWantTrade={handleWantTrade}
          handleWorkHere={handleWorkHere}
          isOpen={isOpen}
        />
        <DesktopNavbar handleWantTrade={handleWantTrade} handleWorkHere={handleWorkHere} />
        <div className="popover-desktop">
          <WantTrade isOpen={wantTradeIsOpen} handleRequestClose={handleRequestClose} />
          <WorkHere
            isOpenWorkHere={workHereIsOpen}
            handleRequestCloseWorkHere={handleRequestCloseWorkHere}
          />
        </div>
      </Styled.Header>
    </Styled.HeaderContainer>
  )
}

interface DesktopNavbarProps {
  handleWantTrade: () => void
  handleWorkHere: () => void
}

const DesktopNavbar = ({ handleWantTrade, handleWorkHere }: DesktopNavbarProps): JSX.Element => {
  return (
    <Styled.DesktopNavbar>
      <ul>
        {NavLinks.map((navLink, index) => {
          if (index === 0) return
          return (
            <li key={String(index + 1)}>
              <Link to={navLink.path}>{navLink.name}</Link>
            </li>
          )
        })}
      </ul>
      <div style={{ width: '18%' }}>
        <button className="want-trade-btn" onClick={handleWantTrade}>
          QUERO NEGOCIAR
        </button>
        <button className="work-here-btn" onClick={handleWorkHere}>
          TRABALHE CONOSCO
        </button>
        {/* <Button className="want-trade-btn" width={'100%'} fullWidth onClick={handleWantTrade}>
          QUERO NEGOCIAR
        </Button>
        <Button className="want-trade-btn" width={'100%'} fullWidth onClick={handleWorkHere}>
          TRABALHE CONOSCO
        </Button> */}
      </div>
    </Styled.DesktopNavbar>
  )
}

interface MobileNavbarProps {
  handleOpenMenu: () => void
  handleWantTrade: () => void
  handleWorkHere: () => void
  isOpen: boolean
}

const MobileNavbar = ({
  handleOpenMenu,
  handleWantTrade,
  handleWorkHere,
  isOpen,
}: MobileNavbarProps): JSX.Element => {
  return (
    <Styled.HeaderNavbar onClick={handleOpenMenu} isOpen={isOpen}>
      <ul>
        {NavLinks.map((navLink, index) => (
          <li key={String(index + 1)}>
            <Link to={navLink.path}>{navLink.name}</Link>
          </li>
        ))}
        <li>
          <Button fullWidth onClick={handleWantTrade}>
            QUERO NEGOCIAR
          </Button>
        </li>
        <li>
          <Button fullWidth onClick={handleWorkHere}>
            TRABALHE CONOSCO
          </Button>
        </li>
      </ul>
    </Styled.HeaderNavbar>
  )
}
