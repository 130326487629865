import { Link } from 'react-router-dom'
import * as Styled from './styles'

export const WarningPrivacyPage = (): JSX.Element => {
  return (
    <>
      <Styled.ContainerBody>
        <div className="container">
          <Styled.ContentContainer>
            <Styled.ContentContainerHeader>
              <h1>Aviso de Privacidade</h1>
            </Styled.ContentContainerHeader>
            <Styled.ContentContainerBody>
              <p className="text-sm">
                <strong>Autor:</strong> Enc.de Dados | <strong>Classificação:</strong> Pública/Dados
                Pessoais | <strong>Versão:</strong> 1.0 | <strong>Data de Publicação:</strong>{' '}
                01/12/2023
              </p>

              <p className="text-md">
                <strong>Olá, seja bem-vindo ao nosso Aviso de Privacidade.</strong>
              </p>

              <p className="text-md text-space">
                A Góes & Nicoladelli – Reconhecida legalmente como “
                <strong>Góes & Nicoladelli Advogados Associados</strong>, inscrita no CNPJ:{' '}
                <strong>03.239.823/0001-62</strong>”, localizada na R. Almirante Tamandaré - 114,
                Santa Bárbara, Criciúma/SC - Cep.: 88.804-290, Controladora de Dados Pessoais, em
                conformidade com as normas legais vigentes relacionadas a proteção de dados
                pessoais, estabelece este <strong>Aviso de Privacidade</strong> com o objetivo de
                demonstrar transparência e disponibilizar informações relevantes para que os
                titulares dos dados pessoais possam exercer seus direitos e obtenham as respostas de
                que precisam antes, durante e ao longo do ciclo de vida dos dados sob sua
                responsabilidade como controlador.
              </p>

              <p className="text-md text-space">
                <strong>1.0 Medidas de Segurança e Privacidade</strong>
                <br />A <strong>Góes & Nicoladelli</strong> estabelece e implementa as melhores
                práticas em segurança da informação e privacidade, adotando padrões, tecnologias e
                capacitação contínua das pessoas envolvidas no tratamento de dados pessoais para
                atender aos requisitos internos e externos, promovendo melhorias contínuas em nossos
                processos.
              </p>

              <p className="text-md text-space">
                <strong>2.0 Atividades de Tratamento de Dados Pessoais</strong>
                <br />A <strong>Góes & Nicoladelli</strong> atua como controladora de dados,
                realizando atividades de tratamento pertinentes ao seu negócio, as quais são
                restritas e têm finalidades estabelecidas. Essas atividades incluem:
                <br />
                <br />
                <ul>
                  <li>a) Estabelecer possíveis relações comerciais ou parcerias;</li>
                  <li>b) Estabelecer relações comerciais e parcerias;</li>
                  <li>
                    c) Avaliar possíveis candidatos a vagas de emprego, estágios ou programas
                    similares;
                  </li>
                  <li>d) Gerir vínculos trabalhistas, estágios ou programas similares;</li>
                  <li>e) Prestar suporte e responder a dúvidas e questionamentos;</li>
                  <li>f) Lidar com requisições de direitos dos titulares de dados pessoais;</li>
                  <li>g) Consulta de informações em sistemas públicos ou na internet;</li>
                  <li>
                    h) Registrar endereços IPs*, portas* e outras informações técnicas
                    automatizadas;
                  </li>
                  <li>
                    i) Monitorar o ambiente físico através de sistemas de CFTV (Circuito Fechado de
                    Televisão).
                  </li>
                </ul>
              </p>

              <p className="text-md text-space">
                <strong>3.0 Finalidades do Tratamento dos Dados Pessoais</strong>
                <br />
                As informações sobre as condições de tratamento dos dados, especificamente, podem
                ser disponibilizadas nesta documentação ou por meio de outros meios apropriados,
                como contratos, avisos ou conforme estabelecido por leis ou regulamentações. A{' '}
                <strong>Góes & Nicoladelli</strong> realiza o tratamento de dados de acordo com as
                finalidades informadas aos titulares, em conformidade com requisitos legais,
                regulações, contratuais, consentimentos, avisos e necessidades internas conforme
                descrito abaixo:
                <br />
                <br />
                <ul>
                  <li>
                    a) Para o exercício regular de direitos e o cumprimento de obrigações legais,
                    regulatórias e contratuais;
                  </li>
                  <li>b) Atender às demandas comerciais e operacionais do negócio;</li>
                  <li>c) Informar sobre melhorias, notícias e condições;</li>
                  <li>d) Legítimo interesse do controlador;</li>
                  <li>e) Responder a dúvidas e questionamentos;</li>
                  <li>f) Atender às solicitações de direitos dos titulares;</li>
                  <li>
                    g) Registrar informações que permitam a comunicação, o acesso aos serviços,
                    datas e horas;
                  </li>
                  <li>h) Divulgar atividades relacionadas ao negócio;</li>
                  <li>i) Monitorar o ambiente, objetivos e acessos de pessoais.</li>
                </ul>
              </p>

              <p className="text-md text-space">
                <strong>4.0 Com Quem Seus Dados Podem Ser Compartilhados</strong>
                <br />
                Enquanto controlador de dados pessoais, tendo em vista a preservação de sua
                privacidade, a <strong>Góes & Nicoladelli</strong> utilizará seus dados ao
                estritamente necessário e observando a adequação, segurança e privacidade.
                <br />
                <br />
                <ul>
                  <li>
                    a) De acordo com as legislações pertinentes, os dados podem ser compartilhados
                    com os governos;
                  </li>
                  <li>
                    b) Os dados podem ser compartilhados com clientes, de acordo com as atividades
                    comerciais;
                  </li>
                  <li>
                    c) Fornecedores de serviços podem ter acessos a dados de acordo com as
                    atividades pertinentes.
                  </li>
                </ul>
                <br />
                Além disso, também existem outras hipóteses em que seus dados poderão ser
                compartilhados, que são:
                <br />
                <br />
                <ul>
                  <li>
                    a) Em cumprimento a determinações legais, requerimentos, requisições ou ordens
                    judiciais, com autoridades judiciais, administrativas ou governamentais
                    competentes;
                  </li>
                  <li>
                    b) Em caso de movimentações societárias, como fusão, aquisição e incorporação, o
                    compartilhamento ocorrerá automaticamente;
                  </li>
                  <li>
                    c) Para proteger os direitos da <strong>Góes & Nicoladelli</strong> em qualquer
                    tipo de conflito, inclusive questões judiciais.
                  </li>
                </ul>
              </p>
              <p className="text-md text-space">
                <strong>5.0 Transferência Internacionais de Dados Pessoais</strong>
                <br />A <strong>Góes & Nicoladelli</strong> poderá realizar transferências
                internacionais para países e/ou organizações que utilizem leis como a LGPD (Lei
                Geral de Proteção de Dados) ou práticas semelhantes.
              </p>
              <p className="text-md text-space">
                <strong>6.0 Compartilhamento e Transferência de Dados Pessoais</strong>
              </p>
              <p className="text-md text-space">
                <strong>6.1 Provedores de serviços</strong>
                <br />
                Por razões de processamento de dados em nome da <strong>Góes & Nicoladelli</strong>,
                poderemos compartilhar dados com prestadores de serviços que estão fora do
                território nacional. Nesse caso, os dados serão tratados de forma a proteger a sua
                privacidade, tendo essas empresas o dever contratual de garantir proteção compatível
                com a legislação em vigor no Brasil e com outras condições de tratamento acordadas
                entre os titulares de dados e a <strong>Góes & Nicoladelli</strong>.
                <br /> Nós podemos utilizar fornecedores para otimizar nossos serviços, prover o
                serviço da <strong>Góes & Nicoladelli</strong> em nosso nome e executar atividades
                relacionadas ao serviço. Para essas atividades, os provedores de serviços têm acesso
                restrito ao necessário aos seus dados pessoais, nos limites da execução das
                atividades solicitadas pela <strong>Góes & Nicoladelli</strong>.
              </p>
              <p className="text-md text-space">
                <strong>6.2 Autoridades Públicas</strong>
                <br />A <strong>Góes & Nicoladelli</strong> também poderá compartilhar suas
                informações com autoridades policiais ou judiciais e autoridades públicas
                competentes. Dentro e fora do país em que você reside, caso seja requerida por
                decisão judicial ou requisição de autoridades, ou se necessário para responder a
                processos judiciais ou para participar em eventuais litígios ou disputas de qualquer
                natureza.
                <br />
                Nessas situações, a <strong>Góes & Nicoladelli</strong> tem o dever de cooperar com
                as autoridades competentes na medida do estipulado por lei. <br />
                Além disso, a <strong>Góes & Nicoladelli</strong> reserva a si a prerrogativa de
                compartilhar informações sobre seus usuários a terceiros quando houver motivos
                suficientes para considerar que a atividade de um usuário é suspeita, ilegal ou
                prejudicial a <strong>Góes & Nicoladelli</strong> ou a terceiros. <br />
                Essa prerrogativa será utilizada pela <strong>Góes & Nicoladelli</strong> quando
                considerar apropriado ou necessário para manter a integridade e a segurança do seu
                serviço, para o cumprimento dos seus termos de usos, para o exercício regular de
                seus direitos e com o intuito de cooperar com a execução e o cumprimento da lei,
                independentemente de existir ou não uma ordem judicial ou administrativa para tanto.
              </p>
              <p className="text-md text-space">
                <strong>7.0 Tempo de Tratamento e Descartes</strong>
                <br />O tempo de tratamento dos dados pessoais pode variar de acordo com a
                legislação, contratos, legítimo interesse, avisos e consentimentos. Os dados
                pessoais que tenham alcançado seus objetivos conforme a legislação ou o interesse da{' '}
                <strong>Góes & Nicoladelli</strong> serão descartados por meio de procedimentos
                operacionais, sejam computacionais ou físicos, de forma que não seja mais possível
                sua recuperação.
              </p>
              <p className="text-md text-space">
                <strong>8.0 Dados de Menores</strong>
                <br />A <strong>Góes & Nicoladelli</strong> trata dados pessoais diretamente de
                menores de 18 anos quando isso é do melhor interesse da criança e quando esses dados
                são fornecidos pelos pais ou responsáveis. Em outros casos, a{' '}
                <strong>Góes & Nicoladelli</strong> não trata dados pessoais diretamente de menores
                de idade, a menos que os menores usem os serviços fornecidos.
              </p>
              <p className="text-md text-space">
                <strong>9.0 Encarregado de Dados</strong>
                <br />A <strong>Góes & Nicoladelli</strong> estabelece para a função de Encarregado
                de Dados Pessoais (DPO) o Sr. <strong>Gedeone Melo da Silva</strong>.
              </p>
              <p className="text-md text-space">
                <strong>11.0 Conhecimentos e Concordâncias de Tratamento</strong>
                <br />
                Leia atentamente as condições de tratamento dos dados pessoais destacados abaixo.
              </p>

              <p className="text-md text-space">
                <strong>11.1 Conhecer o Portfólio de Clientes</strong>
              </p>

              <table className="table">
                <tbody>
                  <tr>
                    <td width="20%">
                      <strong>Visão Geral do Serviço</strong>
                    </td>
                    <td>Conhecer o portfólio de Clientes e Contatos</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Propósito do Uso</strong>
                    </td>
                    <td>Entrar em contato com potencial cliente</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Controlador dos DP</strong>
                    </td>
                    <td>Góes&Nicoladelli Advogados Associados</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>DP a serem coletados</strong>
                    </td>
                    <td>Nome completo, E-mail, Telefones, Cidade e Estado.</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Horário e Local da Coleta</strong>
                    </td>
                    <td>A qualquer momento/Site institucional</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Método do Uso</strong>
                    </td>
                    <td>Os são usados como fornecidos</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Geolocalização dos Dados</strong>
                    </td>
                    <td>Brasil</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Transferência a Operadores</strong>
                    </td>
                    <td>Verdadeiro (Fornecedores de serviços)</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Período de Retenção e Descarte</strong>
                    </td>
                    <td>Até 2 anos</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Sua participação e Escolhas atuais</strong>
                    </td>
                    <td>Informar, atualizar e excluir</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Solicitações e Reclamações</strong>
                    </td>
                    <td>
                      <Link to="/privacidade">https://www.goesnicoladelli.adv.br/privacidade</Link>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Base legal</strong>
                    </td>
                    <td>Mediante o fornecimento de consentimento pelo titular</td>
                  </tr>
                </tbody>
              </table>

              <br />

              <p className="text-md text-space">
                <strong>11.2 Requisição de Direitos, Dúvidas ou Contatos</strong>
              </p>

              <table className="table">
                <tbody>
                  <tr>
                    <td width="20%">
                      <strong>Visão Geral do Serviço</strong>
                    </td>
                    <td>
                      Estabelecer canal de comunicação conforme determina a Lei Geral de Proteção a
                      Dados
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Propósito do Uso</strong>
                    </td>
                    <td>
                      Identificar o titular dos dados ou pessoa designada pelo titular de dados
                      pessoais e realizar processos internos conforme requisição.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Controlador dos DP</strong>
                    </td>
                    <td>Góes&Nicoladelli Advogados Associados</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>DP a serem coletados</strong>
                    </td>
                    <td>
                      Nome completo, n° de Contrato, E-mail, CPF, Carteira Nacional de Habilitação,
                      IP, Carteira de Trabalho, Registro Geral, Telefone e Assinatura.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Horário e Local da Coleta</strong>
                    </td>
                    <td>A qualquer momento/Site institucional</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Método do Uso</strong>
                    </td>
                    <td>Os são usados como fornecidos</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Geolocalização dos Dados</strong>
                    </td>
                    <td>Brasil</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Transferência a Operadores</strong>
                    </td>
                    <td>Verdadeiro (Fornecedores de serviços)</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Período de Retenção e Descarte</strong>
                    </td>
                    <td>Até 5 anos.</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Sua participação e Escolhas atuais</strong>
                    </td>
                    <td>Informar, atualizar e excluir (Quando pertinente)</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Solicitações e Reclamações</strong>
                    </td>
                    <td>
                      <Link to="/privacidade">https://www.goesnicoladelli.adv.br/privacidade</Link>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Base legal</strong>
                    </td>
                    <td>Para o cumprimento de obrigação legal ou regulatória pelo controlador</td>
                  </tr>
                </tbody>
              </table>

              <br />

              <p className="text-md text-space">
                <strong>11.3 Cadastro de Currículos</strong>
              </p>

              <table className="table">
                <tbody>
                  <tr>
                    <td width="20%">
                      <strong>Visão Geral do Serviço</strong>
                    </td>
                    <td>Sistema para cadastro de informações profissionais</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Propósito do Uso</strong>
                    </td>
                    <td>Realizar seleção e recrutamento dos titulares cadastrados</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Controlador dos DP</strong>
                    </td>
                    <td>Góes & Nicoladelli Advogados Associados</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>DP a serem coletados</strong>
                    </td>
                    <td>
                      Nome completo, endereço completo, telefones, e-mail, CPF, RG, OAB,
                      NIT/PIS/PASEP, sexo, ID rede social, formação acadêmica, certificações/cursos,
                      grau de instrução, data de nascimento, experiências profissionais, estado
                      civil, biométrico e de saúde
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Horário e Local da Coleta</strong>
                    </td>
                    <td>A qualquer momento/Site institucional</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Método do Uso</strong>
                    </td>
                    <td>Os são usados como fornecidos</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Geolocalização dos Dados</strong>
                    </td>
                    <td>Brasil</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Transferência a Operadores</strong>
                    </td>
                    <td>Verdadeiro (Fornecedores de serviços)</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Período de Retenção e Descarte</strong>
                    </td>
                    <td>2 anos após a última atualização.</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Sua participação e Escolhas atuais</strong>
                    </td>
                    <td>Informar, Atualizar e Excluir</td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Solicitações e Reclamações</strong>
                    </td>
                    <td>
                      <Link to="/privacidade">https://www.goesnicoladelli.adv.br/privacidade</Link>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Base legal</strong>
                    </td>
                    <td>Mediante o fornecimento de consentimento pelo titular</td>
                  </tr>
                </tbody>
              </table>

              <br />

              <p className="text-md text-space">
                <strong>10.0 Para Ajuda e Requisição de Direitos</strong>
                <br />A <strong>Góes & Nicoladelli</strong>, visando a transparência e o respeito
                aos direitos dos titulares, disponibiliza o canal abaixo para o caso de
                discordâncias, dúvidas e comentários a qualquer momento em:
                <Link to="/privacidade">https://www.goesnicoladelli.adv.br/privacidade</Link>.
              </p>
              <p className="text-md text-space">
                <strong>12.0 Incidentes</strong>
                <br />A <strong>Góes & Nicoladelli</strong> atuará vigorosamente ao responder os
                incidentes de segurança da informação para eliminar e mitigar seus potenciais riscos
                aos titulares de dados. Os incidentes serão reportados aos titulares e autoridades
                competente para conhecimento e providencias criminais ações causadas.
              </p>
              <p className="text-md text-space">
                <strong>13.0 Do Foro</strong>
                <br />
                Para a solução de controvérsias decorrentes do presente instrumento será aplicado
                integralmente a legislação brasileira. Os eventuais litígios deverão ser
                apresentados no foro da comarca em que se encontra a sede da empresa.
              </p>
              <p className="text-md text-space">
                <strong>14.0 Atualização deste Aviso</strong>
                <br />A <strong>Góes & Nicoladelli</strong> reserva-se o direito de realizar
                alterações nesta documentação a qualquer momento, a fim de adequá-la às atividades
                realizadas, atender a questões legais e de negócios.
              </p>
            </Styled.ContentContainerBody>
          </Styled.ContentContainer>
        </div>
      </Styled.ContainerBody>
    </>
  )
}
