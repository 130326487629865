import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Slider = styled(Swiper)`
  --swiper-theme-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: none;
  }
  .swiper-pagination {
    /* margin-top: 1rem; */
    /* top: 10px; */
    bottom: 0;
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      background: rgba(255, 255, 255, 1);
      opacity: 0.5;
    }
  }
`

export const ServiceTitle = styled.h1`
  position: relative;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  &::after {
    content: '';
    background: ${({ theme }) => theme.colors.primary};
    width: 15%;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
`
export const ServiceDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.3;
  strong {
    font-weight: 600;
  }
`
