import styled from 'styled-components'

export const ModalBodyTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 2.5;
  color: ${({ theme }) => theme.colors.secondary};
`
export const ModalBodyParagraph = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  strong {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`

export const ModalBodyIconItem = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  img {
    margin-right: 0.625rem;
  }
`
