import { CommunicationChannels } from 'components/layout/CommunicationChannels'
import { FAQ } from 'components/layout/FAQ'
import { TitleOutlined } from 'components/reusables/TitleOutlined'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const PrivacyPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/6-Privacidade-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <div className="content-container">
          <Styled.ContentContainer>
            <Styled.ContentContainerHeader>
              <h1>Portal de Privacidade</h1>
            </Styled.ContentContainerHeader>
            <Styled.ContentContainerBody>
              <p>
                A Góes e Nicoladelli Advogados Associados conduz suas atividades de forma íntegra,
                zelando pela privacidade de seus clientes e colaboradores, conforme nossa Política
                de Privacidade.
              </p>
              <p>
                É nosso dever garantir a confidencialidade dos dados pessoais a que tem acesso,
                utilizando-os apenas para as finalidades específicas e legítimas, permitindo total
                acesso ao respectivo titular quando permitido, em conformidade com a Lei nº
                13.709/2018
                {'('}Lei Geral de Proteção de Dados{')'}
              </p>
            </Styled.ContentContainerBody>
            <Styled.ContentContainerFooter>
              <Styled.ContentContainerCredits>
                <svg
                  width="48"
                  height="57"
                  viewBox="0 0 48 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5246 23.9569C15.0328 27.3028 17.5854 31.5613 21.7781 33.0588C23.4991 33.6672 25.3818 33.6789 27.1028 33.0471C31.2262 31.5379 33.8596 27.2911 34.3794 23.9569C34.9338 23.9101 35.6615 23.1262 36.4469 20.3067C37.521 16.4577 36.3776 15.8844 35.4074 15.978C35.5922 15.4516 35.7308 14.9134 35.8232 14.3986C37.4633 4.41922 32.6122 4.07995 32.6122 4.07995C32.6122 4.07995 31.8037 2.51226 29.69 1.33064C28.2693 0.476596 26.2943 -0.178559 23.6955 0.0437261C22.8523 0.0788237 22.0553 0.254312 21.3046 0.499995C20.3459 0.827572 19.4681 1.30724 18.6711 1.8688C17.7009 2.48886 16.7769 3.261 15.9684 4.13844C14.6863 5.47215 13.5429 7.19193 13.0462 9.33288C12.6304 10.9357 12.7228 12.6087 13.0693 14.4103C13.1617 14.9368 13.3003 15.4633 13.4851 15.9897C12.5149 15.8961 11.3714 16.4694 12.4456 20.3184C13.2426 23.1262 13.9702 23.9101 14.5246 23.9569Z"
                    fill="#464646"
                  />
                  <path
                    d="M43.5041 37.0482C37.6019 35.5273 32.8086 32.1111 32.8086 32.1111L28.3618 46.3607L28.3502 46.3256L27.7381 48.2443L25.763 42.5702C30.6141 35.7145 24.7812 35.7847 24.4578 35.7964C24.1344 35.7847 18.3016 35.7145 23.1526 42.5702L21.1776 48.2443L20.5654 46.3256L20.5539 46.3607L16.0955 32.1111C16.0955 32.1111 11.3022 35.5273 5.40002 37.0482C0.999406 38.183 0.791502 43.3306 0.964755 45.8694C0.964755 45.8694 1.21886 49.3206 1.47296 50.8415C1.47296 50.8415 10.0663 56.4922 24.4578 56.5039C38.8493 56.5039 47.4427 50.8415 47.4427 50.8415C47.6968 49.3206 47.9509 45.8694 47.9509 45.8694C48.1126 43.3306 47.9047 38.183 43.5041 37.0482Z"
                    fill="#464646"
                  />
                </svg>
                <div>
                  <Styled.CreditsLabel>Encarregado de Dados:</Styled.CreditsLabel>
                  <Styled.CreditsName>Gedeone Melo da Silva</Styled.CreditsName>
                </div>
              </Styled.ContentContainerCredits>
            </Styled.ContentContainerFooter>
          </Styled.ContentContainer>
        </div>
      </Styled.Container>
      <Styled.ContainerBody>
        <div className="container">
          <TitleOutlined id="faq">Saiba Mais</TitleOutlined>
          <FAQ />
          <CommunicationChannels />
        </div>
      </Styled.ContainerBody>
    </>
  )
}
