export const themes = {
  colors: {
    primary: '#CA1F34',
    primary_hover: '#8E1726',
    secondary: '#1B1B1B',
    tertiary: '#2D9CDB',
    gray: {
      500: '#464646',
      400: '#D8D8D8',
      300: '#D9D9D9',
    },
    black: '#000000',
    white: '#FFFFFF',
  },
  sizes: {
    borderRadius: {
      md: '5px',
    },
    breakpoints: {
      sm: '576px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1420px',
    },
  },
  buttons: {
    primary: {
      color: '#FFF',
      backgroundColor: '#CA1F34',
      '&:hover': {
        backgroundColor: '#8E1726',
      },
    },
    secondary: {
      color: '#FFF',
      backgroundColor: 'rgba(255, 255, 255, .2)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .4)',
      },
    },
    tertiary: {
      color: '#FFF',
      backgroundColor: '#2D9CDB',
      '&:hover': {
        backgroundColor: '#2F80ED',
      },
    },
    link: {
      color: '#FFF',
      backgroundColor: 'transparent',
    },
  },
  modal: {
    zIndex: 1072,
    smallMaxWidth: '300px',
    mediumWidth: '500px',
    largeWidth: '800px',

    backgroundColor: '#fff',
    borderColor: 'rgba(0,0,0,.2)',
    borderWidth: '1px',
    borderRadius: '.3rem',
    boxShadow: 'none',
    outline: 0,
    bodyPadding: '1rem',

    titleLineHeight: 1.5,
    titleFontSize: '1.25rem',
    titleFontFamily: 'inherit',
    titleFontWeight: 500,
    titleTextColor: 'inherit',

    closeButtonFontSize: '1.5rem',
    closeButtonFontWeight: 700,
    closeButtonLineHeight: 1.25,
    closeButtonTextColor: '#FFF',
    closeButtonTextShadow: 'none',
    closeButtonOpacity: 0.5,
    closeButtonBackground: 'transparent',
    closeButtonBorder: 0,
    closeButtonPadding: '1rem',

    bodyColor: 'transparent',

    headerColor: '#464646',
    headerPadding: '1rem',
    headerBorderBottom: '1px solid #e9ecef',

    footerColor: 'transparent',
    footerPadding: '1rem',
    footerBorderTop: '1px solid #e9ecef',

    backdropZIndex: 1040,
    backdropColor: '#000',
    backdropOpacity: 0.5,
  },
}
