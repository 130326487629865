import { Button } from 'components/reusables/Button'
import { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Modal from 'react-styled-modal'
import * as Styled from './styles'

export const WarnModal = (): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(true)
  return (
    <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} centered>
      <Modal.Header>
        <Modal.Title>
          <img
            src="/assets/images/goes-logo-white.svg"
            alt="Góes &amp; Nicoladelli"
            draggable={false}
          />
        </Modal.Title>
        <Button style={{ padding: 0 }} variant="link" onClick={() => setModalIsOpen(false)}>
          <FiX />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Styled.ModalBodyTitle>Aviso</Styled.ModalBodyTitle>
        <Styled.ModalBodyParagraph>
          Informamos que todas as informações oficiais de qualquer tipo, veiculadas via e-mail,
          possuem como remetente o domínio <strong>@goesnicoladelli.com.br</strong>.
        </Styled.ModalBodyParagraph>
        <Styled.ModalBodyIconItem>
          <img src="/assets/icons/Phone.svg" alt="Vetor Gráfico" />
          0800-710-2300
        </Styled.ModalBodyIconItem>
        <Styled.ModalBodyIconItem>
          <img src="/assets/icons/Mail.svg" alt="Vetor Gráfico" />
          goesnicoladelli@goesnicoladelli.com.br
        </Styled.ModalBodyIconItem>
        <Styled.ModalBodyParagraph style={{ marginBottom: '1rem' }}>
          Obrigado!
        </Styled.ModalBodyParagraph>
      </Modal.Body>
    </Modal>
  )
}
