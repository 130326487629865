import { ChatPopup } from 'components/layout/ChatPopup'
import { Footer } from 'components/layout/Footer'
import { Navbar } from 'components/layout/Navbar'
import { AboutPage } from 'pages/About'
import { AffiliatedPage } from 'pages/Affiliated'
import { ContactPage } from 'pages/Contact'
import { HomePage } from 'pages/Home'
import { PartnersPage } from 'pages/Partners'
import { ResponsibilitiesPage } from 'pages/Responsibilities'
import { ServicesPage } from 'pages/Services'
import { PrivacyPage } from 'pages/Privacy'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CookieConsentModal } from 'components/layout/CookieConsent'
import { WarningPrivacyPage } from 'pages/WarningPrivacy'
import { CookiesPage } from 'pages/CookiesPage'
import { ReportEqualsMoneyPage } from 'pages/ReportEqualsMoney'

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Navbar />
      <ChatPopup />
      <CookieConsentModal />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="institucional/sobre" element={<AboutPage />} />
        <Route path="institucional/filiais" element={<AffiliatedPage />} />
        <Route path="institucional/socios" element={<PartnersPage />} />
        <Route path="servicos" element={<ServicesPage />} />
        <Route path="contato" element={<ContactPage />} />
        <Route path="responsabilidade-social-ambiental" element={<ResponsibilitiesPage />} />
        <Route path="privacidade" element={<PrivacyPage />} />
        <Route path="aviso-privacidade" element={<WarningPrivacyPage />} />
        <Route path="aviso-cookies" element={<CookiesPage />} />
        <Route path="relatorio-gualdade-salarial" element={<ReportEqualsMoneyPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
