import { TitleOutlined } from 'components/reusables/TitleOutlined'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const ResponsibilitiesPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/5-Respsocial-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <div className="content-container">
          <TitleOutlined>Responsabilidade Social e Ambiental</TitleOutlined>
          <Styled.ContainerSubTitle>
            A Góes &amp; Nicoladelli Advogados realiza ao longo do ano{' '}
            <strong>
              diversas ações sociais, além de apoiar projetos culturais que visam melhorar a
              qualidade de vida das pessoas
            </strong>
            , contribuindo para uma sociedade mais justa e coletiva.
          </Styled.ContainerSubTitle>
          <Styled.ContainerSubTitle>
            Temos como objetivo a cada ano majorar a quantidade das Ações Sociais/Projetos para que
            desperte não somente nos colaboradores o desejo de cooperar com o próximo, mas também
            sensibilizar toda a sociedade em que vivemos.
          </Styled.ContainerSubTitle>
          <Styled.ContainerSubTitle>
            O maior legado que possamos deixar como Empresa é o{' '}
            <strong>reflexo das nossas atitudes com o ser humano.</strong>
          </Styled.ContainerSubTitle>
          <Styled.DesktopAditionalInfos>
            <TitleOutlined marginTop={0}>Contribuição Social</TitleOutlined>
            <div className="social-contribute">
              <div className="social-contribute-item">
                <img src="/assets/icons/CO2Cloud.svg" alt="Vetor Gráfico" />

                <div>
                  <p>Redução de Co2</p>
                  <h4>100100.4 KG</h4>
                </div>
              </div>
              <div className="social-contribute-item">
                <img src="/assets/icons/Tree.svg" alt="Vetor Gráfico" />
                <div>
                  <p>Reduzindo o desmatamento</p>
                  <h4>5522 Árvores</h4>
                </div>
              </div>
              <div className="social-contribute-item">
                <img src="/assets/icons/Coal.svg" alt="Vetor Gráfico" />
                <div>
                  <p>Redução de carbono</p>
                  <h4>40160.6 KG</h4>
                </div>
              </div>
            </div>

            <TitleOutlined marginTop={0}>Certificados</TitleOutlined>
            <Styled.CertifiersContainerDesktop>
              <Styled.CertifiedItem>
                <img
                  src="/assets/images/Certifiers/certificado-de-parceria.png"
                  alt="Imagem do certificado de parceria com Asilo São Vicente de Paulo"
                />
                <h4>Certificado de Parceria</h4>
                <p>Asilo São Vicente de Paulo</p>
              </Styled.CertifiedItem>
              <Styled.CertifiedItem>
                <img
                  src="/assets/images/Certifiers/amigos-do-bairro-da-juventude.png"
                  alt="Imagem do certificado de parceria com Amigos do Bairro da Juventude"
                />
                <h4>Certificado de Parceria</h4>
                <p>Selo Amigos do Bairro da Juventude</p>
              </Styled.CertifiedItem>
              <Styled.CertifiedItem>
                <img
                  src="/assets/images/Certifiers/grupo-guido.png"
                  alt="Imagem do certificado de parceria com Grupo Guido"
                />
                <h4>Certificado de Parceria</h4>
                <p>Guido - Grupo pela Unidade Infantojuvenil de Onco-hematologia</p>
              </Styled.CertifiedItem>
            </Styled.CertifiersContainerDesktop>
          </Styled.DesktopAditionalInfos>
        </div>
      </Styled.Container>
      <Styled.ContainerBody id="certificados">
        <TitleOutlined>Certificados</TitleOutlined>
        <Styled.CertifiersContainer>
          <Styled.CertifiedItem>
            <img
              src="/assets/images/Certifiers/certificado-de-parceria.png"
              alt="Imagem do certificado de parceria com Asilo São Vicente de Paulo"
            />
            <h4>Certificado de Parceria</h4>
            <p>Asilo São Vicente de Paulo</p>
          </Styled.CertifiedItem>
          <Styled.CertifiedItem>
            <img
              src="/assets/images/Certifiers/amigos-do-bairro-da-juventude.png"
              alt="Imagem do certificado de parceria com Amigos do Bairro da Juventude"
            />
            <h4>Certificado de Parceria</h4>
            <p>Selo Amigos do Bairro da Juventude</p>
          </Styled.CertifiedItem>
        </Styled.CertifiersContainer>
      </Styled.ContainerBody>
    </>
  )
}
