import { SubMenu } from 'components/layout/Submenu'
import { VideoBackground } from 'components/reusables/VideoBackground'
import * as Styled from './styles'

export const AboutPage = (): JSX.Element => {
  return (
    <>
      <Styled.Container>
        <VideoBackground
          source="/assets/videos/2-Institucional-compress.mp4"
          type="video/mp4"
          style={{
            position: 'fixed',
          }}
        />
        <SubMenu current="Sobre" />
        <div className="content-container">
          <Styled.ContentContainer>
            <Styled.ContentMainImage
              src="/assets/images/goes-vertical-logo-black.svg"
              alt="Góes &amp; Nicoladelli Logotipo"
              draggable={false}
            />
            <Styled.ContentMainSecondaryImage
              src="/assets/images/about-awards.png"
              alt="Premiação Santander Imagem"
              draggable={false}
            />
            <h3>Premiações</h3>
          </Styled.ContentContainer>
          <Styled.ContentBody>
            <p>
              Criada no ano de 1998, a <strong>Góes e Nicoladelli</strong> surgiu da união de
              profissionais com larga experiência no mercado, atuantes junto às empresas públicas,
              de economia mista, privadas, bancos, financeiras, factorings, seguradoras, fumageiras
              entre outras.
            </p>
            <p>
              O atual cenário macroeconômico brasileiro está cada vez mais competitivo e dinâmico,
              exigindo das empresas e executivos a adoção de uma política mais aguerrida e{' '}
              <strong>eficaz para a concessão e recuperação de crédito.</strong>
            </p>
            <p>
              Por isso, organizamos e desenvolvemos estrutura habilitada e especializada para
              atender nossos clientes, baseados em ampla pesquisa de mercado, com o fim de
              definirmos estratégias que nos permitam{' '}
              <strong>
                minimizar a inadimplência e garantir a lucratividade nas operações realizadas por
                nossos clientes.
              </strong>
            </p>
            <p>
              Dispomos de estrutura funcional organizada e composta por especialistas e funcionários
              altamente capacitados, experientes e devidamente treinados, buscando sempre os
              melhores resultados.
            </p>
          </Styled.ContentBody>
        </div>
      </Styled.Container>

      <Styled.ContainerBody>
        <p>
          Criada no ano de 1998, a <strong>Góes e Nicoladelli</strong> surgiu da união de
          profissionais com larga experiência no mercado, atuantes junto às empresas públicas, de
          economia mista, privadas, bancos, financeiras, factorings, seguradoras, fumageiras entre
          outras.
        </p>
        <p>
          O atual cenário macroeconômico brasileiro está cada vez mais competitivo e dinâmico,
          exigindo das empresas e executivos a adoção de uma política mais aguerrida e{' '}
          <strong>eficaz para a concessão e recuperação de crédito.</strong>
        </p>
        <p>
          Por isso, organizamos e desenvolvemos estrutura habilitada e especializada para atender
          nossos clientes, baseados em ampla pesquisa de mercado, com o fim de definirmos
          estratégias que nos permitam{' '}
          <strong>
            minimizar a inadimplência e garantir a lucratividade nas operações realizadas por nossos
            clientes.
          </strong>
        </p>
        <p>
          Dispomos de estrutura funcional organizada e composta por especialistas e funcionários
          altamente capacitados, experientes e devidamente treinados, buscando sempre os melhores
          resultados.
        </p>
      </Styled.ContainerBody>
    </>
  )
}
