import { WarnModal } from 'components/layout/WarnModal'
import { Button } from 'components/reusables/Button'
import { VideoBackground } from 'components/reusables/VideoBackground'
import { Link } from 'react-router-dom'
import * as Styled from './styles'

export const HomePage = (): JSX.Element => {
  return (
    <>
      <WarnModal />
      <Styled.Container>
        <VideoBackground source="/assets/videos/1-Home-compress.mp4" type="video/mp4" />

        <Styled.ContentContainer>
          <Styled.ContentImage
            src="/assets/images/goes-logo-white.svg"
            alt="Góes &amp; Nicoladelli Logotipo"
            draggable={false}
          />
          <Styled.ContentTitle>Acreditar na parceria é alcançar o sucesso</Styled.ContentTitle>
          <Styled.ContentParagraph>
            Desde 1998 atuando com eficácia para a concessão e recuperação de crédito de junto às
            empresas públicas, de economia mista, privadas, bancos, financeiras, factorings,
            seguradoras, fumageiras entre outras.
          </Styled.ContentParagraph>
          <Link to="/institucional/sobre" className="button-now-services">
            <Button variant="secondary" size="sm">
              CONHEÇA NOSSOS SERVIÇOS
            </Button>
          </Link>
        </Styled.ContentContainer>
      </Styled.Container>
    </>
  )
}
