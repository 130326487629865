import { Button } from 'components/reusables/Button'
import { CookiesTextMock } from 'config/CookiesTextMock'
import { FiX } from 'react-icons/fi'
import Modal from 'react-styled-modal'

interface CookieConsentProps {
  modalIsOpen: boolean
  setModalIsOpen: (value: boolean) => void
}

const ModalCookieConsent = ({ modalIsOpen, setModalIsOpen }: CookieConsentProps): JSX.Element => {
  return (
    <>
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} centered>
        <Modal.Header>
          <Modal.Title style={{ color: '#fff' }}>{CookiesTextMock.title}</Modal.Title>
          <Button style={{ padding: 0 }} variant="link" onClick={() => setModalIsOpen(false)}>
            <FiX />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{CookiesTextMock.text}</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export { ModalCookieConsent }
