import { TitleOutlined } from 'components/reusables/TitleOutlined'
import { Form } from './Form'
import * as Styled from './styles'
export const CommunicationChannels = (): JSX.Element => {
  return (
    <Styled.Container id="canal-de-comunicacao">
      <TitleOutlined outColor="tertiary">Canal de Comunicação</TitleOutlined>
      <Styled.SubTitle>
        O canal de comunicação disponibilizado pela Góes e Nicoladelli, para que os titulares de
        dados realizem contato com encarregado de dados, será através do Solicitação de Privacidade,
        nos termos dos artigos 18 da Lei 13.709/2018, por meio de formulário disponibilizado neste
        site.
      </Styled.SubTitle>
      <Form />
    </Styled.Container>
  )
}
