import { SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import { Pagination } from 'swiper'

import * as Styled from './styles'

export const PartnersSlide = (): JSX.Element => {
  return (
    <>
      <Styled.Slider
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {Partners.map((partner, index) => (
          <SwiperSlide key={String(index + 1)}>
            <Styled.Partner>
              <img src={partner.image} alt={`Imagem de ${partner.name}`} />
              <h3>{partner.name}</h3>
              <h4>{partner.role}</h4>
              <p>{partner.description}</p>
            </Styled.Partner>
          </SwiperSlide>
        ))}
      </Styled.Slider>
    </>
  )
}

const Partners = [
  {
    image: '/assets/images/Partners/Gustavo-Rodrigo.png',
    name: 'Gustavo Rodrigo Góes Nicoladelli',
    role: 'Sócio-Administrador',
    description:
      'Formado em Direito pela Universidade do Vale do Rio dos Sinos – Unisinos (RS), Pós- Graduado em Direito Processual Civil, sócio do escritório de advocacia Góes e Nicoladelli.',
  },
  {
    image: '/assets/images/Partners/Elisiane-de-Dornelles.png',
    name: 'ELISIANE DE DORNELLES FRASSETTO',
    role: 'Sócio',
    description:
      'Formada em direito pela UNISINOS , pós-graduada em Processo Civil (Damásio), conselheira da OAB/SC subseção de Criciúma, sócia do escritório de advocacia Góes & Nicoladelli.',
  },
  {
    image: '/assets/images/Partners/Rodrigo-Frassetto.png',
    name: 'RODRIGO FRASSETTO GOES',
    role: 'Sócio-Administrador',
    description:
      'Formado em direito pela UNESC, especialista em Direito Bancário (FGV), pós-graduado/MBA em Getão Empresarial (FGV), em Negócios Internacionais (ISCTE Lisboa-PT) e Executivo Global (FGV), Mestrando em Gestão (ISCTE Business School), Sócio do escritório de advocacia Góes Nicoladelli',
  },
]
