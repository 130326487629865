import { Link } from 'react-router-dom'
import * as Styled from './styles'

interface SubMenuProps {
  current: 'Sobre' | 'Filiais' | 'Sócios'
}

export const SubMenu = ({ current }: SubMenuProps): JSX.Element => {
  return (
    <Styled.SubmenuHeader>
      <nav>
        <ul>
          {SubMenuLinks.map((item, index) => (
            <li key={String(index + 1)}>
              <Link to={item.path} className={current === item.name ? 'active' : ''}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </Styled.SubmenuHeader>
  )
}

const SubMenuLinks = [
  {
    name: 'Sobre',
    path: '/institucional/sobre',
  },
  {
    name: 'Filiais',
    path: '/institucional/filiais',
  },
  {
    name: 'Sócios',
    path: '/institucional/socios',
  },
]
