import styled from 'styled-components'

export const Container = styled.main`
  background: url('/assets/images/BG-3-Serviços.png') no-repeat 30% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1000px;
  padding: 1rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.md}) {
    background-size: 2100px;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    min-height: 80vh;
  }
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.xxl}) {
    background: none;
    background-size: 3000px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 1024px;
    margin: 0 auto;
  }
`
export const ServiceContainer = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 60%;
    display: flex;
    flex-direction: column;
  }
`

export const ServiceDescription = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.white};
`
