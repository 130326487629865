import { TitleOutlined } from 'components/reusables/TitleOutlined'
import * as Styled from './styles'
import './style.css'

export const ReportEqualsMoneyPage = (): JSX.Element => {
  return (
    <>
      <Styled.ContainerTitle>
        <div className="container">
          <TitleOutlined id="faq">Relatório igualdade salarial</TitleOutlined>
        </div>
      </Styled.ContainerTitle>
      <Styled.ContainerBody>
        <div className="container container-mobile">
          <Styled.ContentContainer>
            <Styled.ContentContainerHeader>
              <h1>CNPJ: 10.376.290/0001-26</h1>
            </Styled.ContentContainerHeader>
            <Styled.ContentContainerBody>
              <p>GN ASSESSORIA E COBRANCAS LTDA</p>
            </Styled.ContentContainerBody>
            <Styled.ContentContainerFooter>
              <Styled.ContentContainerCredits>
                <div style={{ width: '100%' }}>
                  <a href="assets/documents/10376290000126.pdf" className="link" target="_blank">
                    Clique aqui
                  </a>
                </div>
              </Styled.ContentContainerCredits>
            </Styled.ContentContainerFooter>
          </Styled.ContentContainer>

          <Styled.ContentContainer>
            <Styled.ContentContainerHeader>
              <h1>CNPJ: 03.239.823/0001-62</h1>
            </Styled.ContentContainerHeader>
            <Styled.ContentContainerBody>
              <p>GOES & NICOLADELLI ADVOGADOS ASSOCIADOS</p>
            </Styled.ContentContainerBody>
            <Styled.ContentContainerFooter>
              <Styled.ContentContainerCredits>
                <div style={{ width: '100%' }}>
                  <a href="assets/documents/03239823000162.pdf" className="link" target="_blank">
                    Clique aqui
                  </a>
                </div>
              </Styled.ContentContainerCredits>
            </Styled.ContentContainerFooter>
          </Styled.ContentContainer>
        </div>
      </Styled.ContainerBody>
    </>
  )
}
