import { Link } from 'react-router-dom'
import { FooterCopyright } from './Copyright'
import * as Styled from './styles'

const Socials = [
  {
    icon: '/assets/icons/Facebook.svg',
    url: 'https://www.facebook.com/goesnicoladelli/',
  },
  {
    icon: '/assets/icons/Linkedin.svg',
    url: 'https://www.linkedin.com/company/10628690/',
  },
  {
    icon: '/assets/icons/Skype.svg',
    url: 'skype.com',
  },
  {
    icon: '/assets/icons/Whatsapp.svg',
    url: 'https://api.whatsapp.com/send?phone=554834612300&text=Ol%C3%A1.%20Estou%20entrando%20em%20contato%20a%20partir%20do%20site%20da%20G%C3%B3es%20Nicoladelli',
  },
]

export const Footer = (): JSX.Element => {
  return (
    <>
      <Styled.Footer>
        <Styled.FooterContainer>
          <img
            className="goes-logo"
            src="/assets/images/goes-logo-gray.svg"
            alt="Góes &amp; Nicoladelli Logotipo"
            draggable={false}
          />
          <Styled.FooterInfosContainer>
            <Styled.FooterInfo>
              <Styled.FooterAddressInfo>
                <p>Rua Almirante Tamandaré</p>
                <p>Nº 114 - Santa Bárbara</p>
                <p>Criciúma/SC - CEP 88.804-290</p>
                <p>+55 {'(48)'} 3461.2300 - 0800 710</p>
                <p>goesnicoladelli@goesnicoladelli.com.br</p>
              </Styled.FooterAddressInfo>
              <Styled.FooterSocialsInfo>
                {Socials.map((item, index) => (
                  <Styled.FooterSocialItem href={item.url} target="_blank" key={String(index + 1)}>
                    <img src={item.icon} alt="Vetor Gráfico" draggable={false} />
                  </Styled.FooterSocialItem>
                ))}
              </Styled.FooterSocialsInfo>
            </Styled.FooterInfo>
            <Styled.FooterNavLinks>
              <Styled.FooterNavItem>
                <h4>Institucional</h4>
                <ul>
                  <li>
                    <Link to="/institucional/sobre">Sobre</Link>
                  </li>
                  <li>
                    <Link to="/institucional/filiais">Filiais</Link>
                  </li>
                  <li>
                    <Link to="/institucional/socios">Sócios</Link>
                  </li>
                </ul>
              </Styled.FooterNavItem>
              <Styled.FooterNavItem>
                <h4>Trabalhe conosco</h4>
                <ul>
                  <li>
                    <a
                      href="https://platform.senior.com.br/hcmrs/hcm/curriculo/?tenant=goesnicoladellicombr&tenantdomain=goesnicoladelli.com.br#!/vacancies/list"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Oportunidades
                    </a>
                  </li>
                </ul>
              </Styled.FooterNavItem>
              <Styled.FooterNavItem>
                <h4>Serviços</h4>
                <ul>
                  <li>
                    <Link to="/servicos">Jurídico</Link>
                  </li>
                  <li>
                    <Link to="/servicos">Jurídico Ativo</Link>
                  </li>
                  <li>
                    <Link to="/servicos">Jurídico Passivo</Link>
                  </li>
                </ul>
              </Styled.FooterNavItem>
              <Styled.FooterNavItem>
                <h4>
                  Portal de Privacidade
                </h4>
                <ul>
                  <li>
                    <Link to="/aviso-privacidade">Aviso de Privacidade</Link>
                  </li>
                  <li>
                    <Link to="/aviso-cookies">Aviso de Cookies</Link>
                  </li>
                  <li>
                    <Link to="/privacidade#faq">Saiba Mais</Link>
                  </li>
                  <li>
                    <Link to="/privacidade#canal-de-comunicacao">Canal de comunicação</Link>
                  </li>
                </ul>
              </Styled.FooterNavItem>
              <Styled.FooterNavItem>
                <h4>Responsabilidade Social</h4>
                <ul>
                  <li>
                    <Link to="/responsabilidade-social-ambiental">Certificados</Link>
                  </li>
                </ul>
              </Styled.FooterNavItem>
            </Styled.FooterNavLinks>
          </Styled.FooterInfosContainer>
          <Styled.FooterSocialsInfoDesktop>
            {Socials.map((item, index) => (
              <Styled.FooterSocialItem href={item.url} target="_blank" key={String(index + 1)}>
                <img src={item.icon} alt="Vetor Gráfico" draggable={false} />
              </Styled.FooterSocialItem>
            ))}
          </Styled.FooterSocialsInfoDesktop>
        </Styled.FooterContainer>
      </Styled.Footer>
      <FooterCopyright />
    </>
  )
}
