import { ErrorMessage } from '@hookform/error-message'
import { Button } from 'components/reusables/Button'
import { SubmitHandler, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import FormsService from 'services/FormsService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Styled from './styles'
import { Link } from 'react-router-dom'

interface ContactFormInputs {
  name: string
  email: string
  phone: string
  city: string
  message: string
  toggle: string | boolean
}

export const ContactForm = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactFormInputs>({
    mode: 'onChange',
  })
  const Alert = withReactContent(Swal)

  const onSubmit: SubmitHandler<ContactFormInputs> = async ({
    name,
    city,
    email,
    message,
    phone,
  }) => {
    try {
      await FormsService.sendContactForm({
        nome: name,
        cidade: city,
        email,
        mensagem: message,
        telefone: phone.replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/-/g, ''),
      })
      reset()
      Alert.fire({
        title: 'Enviado com sucesso!',
        text: 'Em breve entraremos em contato com você.',
        icon: 'success',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      })
    } catch (err) {
      Alert.fire({
        title: 'Erro!',
        text: 'Não foi possível enviar o formulário.',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      })
      console.log('Não foi possível enviar o fomulário de contato')
    }
  }
  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.Input
        placeholder="Nome completo*"
        required
        {...register('name', {
          required: true,
        })}
      />
      <Styled.Input
        placeholder="Informe seu melhor email*"
        type="email"
        required
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Preencha com um e-mail válido',
          },
        })}
      />
      {errors?.email?.message && (
        <ErrorMessage
          errors={errors}
          name="email"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />
      )}
      <Styled.Input
        as={ReactInputMask}
        mask="(99) 99999-9999"
        placeholder="Fone*"
        required
        {...register('phone', {
          required: true,
        })}
      />
      <Styled.Input
        placeholder="Cidade*"
        required
        {...register('city', {
          required: true,
        })}
      />
      <Styled.TextArea
        placeholder="Mensagem*"
        required
        {...register('message', {
          required: true,
        })}
      />
      <Styled.FormGroup className="accept-form">
        <Styled.CheckboxLabelAux>
          <input
            type="checkbox"
            required
            {...register('toggle', {
              required: true,
            })}
          />
          <span style={{ color: '#fff' }}>
            Li e concordo que os dados inseridos serão utilizados para contato administrativos e
            processos operacionais, de acordo com o item 11.1 no
          </span>{' '}
          <Link to="/aviso-privacidade" style={{ color: 'yellow' }}>
            Aviso de Privacidade
          </Link>{' '}
          <span style={{ color: '#fff' }}>e a Lei Geral de Proteção de Dados.</span>
        </Styled.CheckboxLabelAux>
      </Styled.FormGroup>
      <Button type="submit">ENVIAR</Button>
    </Styled.Form>
  )
}
