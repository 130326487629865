import styled from 'styled-components'

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  button {
    cursor: pointer;
    transition: transform 200ms ease-in-out;
  }
`

export const QuestionBody = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: all 200ms ease-in-out;
  padding: 0 1rem;
  p {
    opacity: 0;
    transition: 300ms ease-in-out;
  }
`

export const Question = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  color: ${({ theme }) => theme.colors.white};
  button {
    border: none;
    background: transparent;
    padding: 0;
  }
  h4 {
    font-weight: 800;
    font-size: 20px;
  }

  transition: all 200ms ease-in-out;
  &.active {
    background: ${({ theme }) => theme.colors.secondary};
    ${QuestionHeader} button {
      transform: rotateX(180deg);
    }
    ${QuestionBody} {
      max-height: 500px;
      margin-top: 1rem;
      padding: 1rem;
      p {
        opacity: 1;
        margin-bottom: 0.5rem;
        strong {
          font-weight: 700;
        }
      }
    }
  }
`
