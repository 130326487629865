import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
  button {
    margin-top: 1rem;
  }
`

export const Input = styled.input`
  border: none;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 0.5rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const CheckboxLabelAux = styled.label`
  display: block;
  grid-template-columns: repeat(2, auto);
  padding-right: 2.2rem;
  line-height: 25px;

  input[type='checkbox']:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='checkbox'] {
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 5px;
    margin-right: 10px;
  }

  input[type='checkbox']:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary}
      url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
      2px 2px no-repeat;
  }
`

export const FormGroup = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    &:not(.accept-form) {
      max-width: 50%;
    }
  }
`

export const TextArea = styled.textarea`
  border: none;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 0.5rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
