import { ErrorMessage } from '@hookform/error-message'
import { Button } from 'components/reusables/Button'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import ReactInputMask from 'react-input-mask'
import FormsService from 'services/FormsService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Input } from '../ContactForm/styles'
import * as Styled from './styles'

interface ContactFormInputs {
  name: string
  email: string
  phone: string
  city: string
  message: string
}

export const ChatPopup = (): JSX.Element => {
  const [isChatOpen, setIsChatOpen] = useState(false)

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ContactFormInputs>({
    mode: 'onChange',
  })

  const Alert = withReactContent(Swal)

  const onSubmit: SubmitHandler<ContactFormInputs> = async ({
    name,
    city,
    email,
    message,
    phone,
  }) => {
    try {
      await FormsService.sendContactForm({
        nome: name,
        cidade: city,
        email,
        mensagem: message,
        telefone: phone.replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/-/g, ''),
      })
      reset()
      Alert.fire({
        title: 'Sua solicitação foi enviada com sucesso!',
        text: 'Em breve entraremos em contato com você.',
        icon: 'success',
        confirmButtonColor: '#CA1F34',
      })
    } catch (err) {
      Alert.fire({
        title: 'Erro!',
        text: 'Não foi possível enviar o formulário.',
        icon: 'error',
        confirmButtonColor: '#CA1F34',
      })
      console.log('Não foi possível enviar o fomulário de contato')
    }
  }
  return (
    <>
      <Styled.ChatPopup isOpen={isChatOpen}>
        <header>
          Deixe uma mensagem
          <button onClick={() => setIsChatOpen(false)}>
            <FiX />
          </button>
        </header>
        <main>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder="Nome completo*"
              required
              {...register('name', {
                required: true,
              })}
            />
            <Input
              placeholder="Informe seu melhor email*"
              required
              type="email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Preencha com um e-mail válido',
                },
              })}
            />
            {errors?.email?.message && (
              <ErrorMessage
                errors={errors}
                name="email"
                render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
              />
            )}
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Preencha com um número válido',
                },
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  as={ReactInputMask}
                  mask="(99) 99999-9999"
                  placeholder="Fone*"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Input
              placeholder="Cidade*"
              required
              {...register('city', {
                required: true,
              })}
            />
            <Input
              as="textarea"
              placeholder="Mensagem*"
              required
              {...register('message', {
                required: true,
              })}
            />
            <Button type="submit">ENVIAR</Button>
          </form>
        </main>
      </Styled.ChatPopup>
      <Styled.ChatPopupContainer onClick={() => setIsChatOpen(true)}>
        <img src="/assets/icons/ChatIcon.svg" alt="Vetor Gráfico" />
      </Styled.ChatPopupContainer>
    </>
  )
}
