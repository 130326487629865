import styled from 'styled-components'

export const Container = styled.main`
  background: url('/assets/images/BG-4-Contato.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1050px;
  padding: 1rem;
  @media (min-width: 768px) {
    background-size: 2000px;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    min-height: 80vh;
    .content-container {
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      /* margin-bottom: 4rem; */
    }
  }
`

export const ContainerSubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2rem;
  strong {
    font-weight: 600;
  }
`

export const ContentContainer = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url('/assets/images/noise.png') no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align: center;
    font-weight: 700;
    margin-top: 0.675rem;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 70%;
  }
`

export const ContainerBody = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[500]};
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    display: none;
  }
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ContactContainerDesktop = styled(ContactContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    margin-top: 2rem;
    display: flex;
  }
`

export const ContactItem = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  svg {
    width: 20px;
    height: 20px;
  }
  p {
    margin-left: 0.675rem;
  }
`
